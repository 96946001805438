@import '~intl-tel-input/build/css/intlTelInput.css';

.iti__flag {
	// background-image: url('https://raw.githubusercontent.com/jackocnr/intl-tel-input/master/build/img/flags.png');
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	/* .iti__flag {
		background-image: url('https://raw.githubusercontent.com/jackocnr/intl-tel-input/master/build/img/flags@2x.png');
	} */
}

.iti {
	width: 200px;
	font-size: var(--font-size-base) !important;
	line-height: var(--line-height-sm) !important;

	:focus {
		border: none;
		outline: none;
	}

	&__selected-dial-code {
		display: none;
	}

	&__selected-flag {
		padding: 0 6px 0 6px;
	}

	&__country-list {
		background-color: var(--background-color);
	}
}

.iti__country-name {
	color: var(--paragraph-color);
}

.iti--separate-dial-code .iti__selected-flag {
	background-color: transparent;
}

.input-phone {
	border: 0;
	color: var(--paragraph-color);
	font-size: var(--font-size-base) !important;
	line-height: var(--line-height-sm) !important;
	display: flex;

	&.ng-invalid.hasError:not(.ng-untouched) {
		color: var(--red-mid);
	}
}

.iti__country-list {
	border-radius: var(--rounded-lg);
}

.iti-mobile .iti__country-list {
	max-height: 31rem;
	top: 17.5rem;
}

:host-context(app-input.ng-valid.ng-pristine, app-input.ng-valid.ng-dirty) {
	.input-phone {
		color: var(--green-mid);
	}
}
