.flex-grow-1 {
	flex-grow: 1;
}

.cursor-pointer {
	cursor: pointer;
}

.africa-do-sul {
	background-image: url('assets/rounded-flags/africa-do-sul.svg');
}
.chile {
	background-image: url('assets/rounded-flags/chile.svg');
}
.estados-unidos {
	background-image: url('assets/rounded-flags/estados-unidos.svg');
}
.irlanda {
	background-image: url('assets/rounded-flags/irlanda.svg');
}
.montenegro {
	background-image: url('assets/rounded-flags/montenegro.svg');
}
.suecia {
	background-image: url('assets/rounded-flags/suecia.svg');
}
.alemanha {
	background-image: url('assets/rounded-flags/alemanha.svg');
}
.china {
	background-image: url('assets/rounded-flags/china.svg');
}
.finlandia {
	background-image: url('assets/rounded-flags/finlandia.svg');
}
.israel {
	background-image: url('assets/rounded-flags/israel.svg');
}
.none {
	background-image: url('assets/rounded-flags/none.svg');
}
.suica {
	background-image: url('assets/rounded-flags/suica.svg');
}
.argentina {
	background-image: url('assets/rounded-flags/argentina.svg');
}
.colombia {
	background-image: url('assets/rounded-flags/colombia.svg');
}
.franca {
	background-image: url('assets/rounded-flags/franca.svg');
}
.italia {
	background-image: url('assets/rounded-flags/italia.svg');
}
.noruega {
	background-image: url('assets/rounded-flags/noruega.svg');
}
.tunisia {
	background-image: url('assets/rounded-flags/tunisia.svg');
}
.australia {
	background-image: url('assets/rounded-flags/australia.svg');
}
.coreia-do-sul {
	background-image: url('assets/rounded-flags/coreia-do-sul.svg');
}
.georgia {
	background-image: url('assets/rounded-flags/georgia.svg');
}
.jamaica {
	background-image: url('assets/rounded-flags/jamaica.svg');
}
.nova-zelandia {
	background-image: url('assets/rounded-flags/nova-zelandia.svg');
}
.turquia {
	background-image: url('assets/rounded-flags/turquia.svg');
}
.austria {
	background-image: url('assets/rounded-flags/austria.svg');
}
.croacia {
	background-image: url('assets/rounded-flags/croacia.svg');
}
.grecia {
	background-image: url('assets/rounded-flags/grecia.svg');
}
.japao {
	background-image: url('assets/rounded-flags/japao.svg');
}
.peru {
	background-image: url('assets/rounded-flags/peru.svg');
}
.ucrania {
	background-image: url('assets/rounded-flags/ucrania.svg');
}
.belgica {
	background-image: url('assets/rounded-flags/belgica.svg');
}
.dinamarca {
	background-image: url('assets/rounded-flags/dinamarca.svg');
}
.holanda {
	background-image: url('assets/rounded-flags/holanda.svg');
}
.libano {
	background-image: url('assets/rounded-flags/libano.svg');
}
.polonia {
	background-image: url('assets/rounded-flags/polonia.svg');
}
.uruguai {
	background-image: url('assets/rounded-flags/uruguai.svg');
}
.bolivia {
	background-image: url('assets/rounded-flags/bolivia.svg');
}
.emirados {
	background-image: url('assets/rounded-flags/emirados.svg');
}
.hungria {
	background-image: url('assets/rounded-flags/hungria.svg');
}
.luxemburgo {
	background-image: url('assets/rounded-flags/luxemburgo.svg');
}
.portugal {
	background-image: url('assets/rounded-flags/portugal.svg');
}
.venezuela {
	background-image: url('assets/rounded-flags/venezuela.svg');
}
.brasil {
	background-image: url('assets/rounded-flags/brasil.svg');
}
.escocia {
	background-image: url('assets/rounded-flags/escocia.svg');
}
.india {
	background-image: url('assets/rounded-flags/india.svg');
}
.malta {
	background-image: url('assets/rounded-flags/malta.svg');
}
.republica-checa {
	background-image: url('assets/rounded-flags/republica-checa.svg');
}
.bulgaria {
	background-image: url('assets/rounded-flags/bulgaria.svg');
}
.eslovenia {
	background-image: url('assets/rounded-flags/eslovenia.svg');
}
.indonesia {
	background-image: url('assets/rounded-flags/indonesia.svg');
}
.marrocos {
	background-image: url('assets/rounded-flags/marrocos.svg');
}
.romenia {
	background-image: url('assets/rounded-flags/romenia.svg');
}
.canada {
	background-image: url('assets/rounded-flags/canada.svg');
}
.espanha {
	background-image: url('assets/rounded-flags/espanha.svg');
}
.inglaterra {
	background-image: url('assets/rounded-flags/inglaterra.svg');
}
.mexico {
	background-image: url('assets/rounded-flags/mexico.svg');
}
.russia {
	background-image: url('assets/rounded-flags/russia.svg');
}
.macedonia-do-norte {
	background-image: url('assets/rounded-flags/macedonia-do-norte.svg');
}
.servia {
	background-image: url('assets/rounded-flags/servia.svg');
}
.eslovaquia {
	background-image: url('assets/rounded-flags/eslovaquia.svg');
}
.kosovo {
	background-image: url('assets/rounded-flags/kosovo.svg');
}
.moldavia {
	background-image: url('assets/rounded-flags/moldavia.svg');
}
.armenia {
	background-image: url('assets/rounded-flags/armenia.svg');
}

