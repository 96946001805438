/* You can add global styles to this file, and also import other style files */
@import 'reset';
@import 'utils';
@import '~ngx-print-element/styles.css';

// CSS VARIABLES
:root {
	--page-bg: #fefefe;
	--widget-bg: #fff;
	--primary-color: #ff813c;
	--text-color: #30323b;
	--background-color: #fefefe;
	--accent-background-color: #f8f8f8;
	--border-color: #eaeaea;

	--primary-font: 'Open Sans', sans-serif;
	--heading-font-size: var(--font-size-xl) !important;
	--heading-line-height: var(--line-height-xl) !important;

	--green-dark: #389540;
	--green-mid-dark: #53a45b;
	--green-mid: #71c879;
	--green-mid-light: #ccf1cc;
	--green-light: #e5ffe7;

	--gray-light: #f8f8f8;
	--gray-mid-light: #eaeaea;

	--yellow-mid: #e2ac54;
	--yellow-mid-light: #ffe5a4;
	--yellow-light: #fffde2;

	--red-mid: #de1d48;
	--red-mid-light: #fedee5;
	--red-light: #fff3f6;

	--blue-mid: #1d8ede;
	--blue-mid-light: #deedfe;
	--blue-light: #f3f4ff;

	--featured-color: #f0f082;
	--featured-border-color: #f0f082;

	--featured-text-color: #fff;
	--featured-text-background-color: var(--primary-color);

	--heading-color: var(--text-color);
	--paragraph-color: var(--text-color);
	--price-color: var(--text-color);

	--featured-heading-color: var(--heading-color);
	--featured-paragraph-color: var(--paragraph-color);

	--rounded-none: 0px;
	--rounded-sm: 0.125rem; // 2px
	--rounded: 0.25rem; // 4px
	--rounded-md: 0.375rem; // 6px
	--rounded-lg: 0.5rem; // 8px
	--rounded-xl: 0.75rem; // 12px
	--rounded-2x1: 1rem; // 16px
	--rounded-3x1: 1.5rem; // 24px
	--rounded-full: 9999px;

	--rounded-tagme-buttons: 0.625rem; // 10px

	// Typography
	--font-size-xs: 0.75rem; // 12px
	--line-height-xs: 1rem; // 16px

	--font-size-sm: 0.875rem; // 14px
	--line-height-sm: 1.125rem; // 18px

	--font-size-base: 1rem; // 16px
	--line-height-base: 1.5rem; // 24px

	--font-size-lg: 1.125rem; // 18px
	--line-height-lg: 1.75rem; // 28px

	--font-size-xl: 1.25rem; // 20px
	--line-height-xl: 1.75rem; // 28px

	--font-size-2xl: 1.5rem; // 24px
	--line-height-2xl: 2rem; // 32px

	--font-size-3xl: 1.875rem; // 30px
	--line-height-3xl: 2.25rem; // 36px

	--font-size-4xl: 2.25rem; // 36px
	--line-height-4xl: 2.5rem; // 40px

	--font-size-5xl: 3rem; // 48px
	--line-height-5xl: 1; // 1

	--font-size-6xl: 3.75rem; // 60px
	--line-height-6xl: 1; // 1

	--font-size-7xl: 4.5rem; // 72px
	--line-height-7xl: 1; // 1

	--font-size-8xl: 6rem; // 96px
	--line-height-8xl: 1; // 1

	--font-size-9xl: 8rem; // 128px
	--line-height-9xl: 1; // 1
}

html {
	font-size: 16px;
}

html,
body {
	scroll-behavior: auto !important;
}

body {
	font-family: sans-serif;
	font-family: var(--primary-font);
	font-size: var(--font-size-sm);
	font-weight: 300;
	color: var(--text-color);
	margin: 0;
	padding: 0;
	min-height: 100vh;
	//min-width: 100vw;
	background: var(--background-color);

	@media (min-width: 768px) {
		display: flex;
		justify-content: center;
	}

	&.menu-hidden {
		overflow: initial;
	}

	&.menu-show {
		overflow: hidden;

		@media (min-width: 768px) {
			&:before {
				content: '';
				width: 100vw;
				height: 100vh;
				position: fixed;
				left: 0;
				top: 0;
				background: rgba(0, 0, 0, 0.6);
				z-index: 4;
			}
		}
	}
}

h1 {
	font-size: var(--heading-font-size);
	line-height: var(--heading-line-height);
}

a {
	color: var(--primary-color);
}

.content {
	&__body {
		display: grid;
		min-height: 100vh;
		padding: 0 10px;
	}
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
}

/* Modal v2 styles */
app-modal {
	display: none;
}

body.app-modal-open {
	overflow: hidden;
}

.icon svg {
	pointer-events: none;
}

.alert {
	border: 1px solid var(--gray-mid-light);
	background: var(--gray-light);
	font-size: var(--font-size-sm) !important;
	line-height: var(--line-height-sm) !important;
	padding: 10px;
	border-radius: var(--rounded);
	margin: 10px 0;

	font-family: sans-serif;

	&.attention {
		color: var(--yellow-mid);
		border-color: var(--yellow-mid-light);
		background-color: var(--yellow-light);
	}

	&.success {
		color: var(--green-dark);
		border-color: var(--green-mid-light);
		background-color: var(--green-light);
	}

	&.warning {
		color: var(--red-mid);
		border-color: var(--red-mid-light);
		background-color: var(--red-light);
	}

	&.info {
		color: var(--blue-mid);
		border-color: var(--blue-mid-light);
		background-color: var(--blue-light);
	}

	&.flex_row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	h3 {
		font-size: var(--font-size-xs) !important;
		line-height: var(--line-height-xs) !important;
	}
}

.badge.badge {
	padding: 0 3px;
}

.counter {
	user-select: none;
}

.loader {
	background: var(--accent-background-color) no-repeat !important;
}

tag-loading {
	--progressColor: var(--primary-color);
}

.waiter-mode.waiter-mode {
	header {
		width: auto;
	}

	.carousel__container {
		display: none;
	}

	.venue-header {
		display: contents;
	}

	.content__body--scroll-down.content__body--category-fixed .header_in_top {
		transform: none !important;
	}

	.brand-banner,
	.header_in_top__title,
	.search-section,
	.menu-section-icon,
	.menu__pay {
		display: none;
	}

	.search-form.search-form {
		height: 61px;
		top: 0;
		padding: 10px 10px 0;
		width: 100%;
		min-width: 0;

		input {
			width: 100%;
		}

		.search-input-icon {
			top: 50%;
			transform: translateY(calc(-57% + 5px));
		}
	}

	.search-results {
		&.open {
			top: calc(100% + 17px);
		}
	}

	.menus {
		top: 60px;
		transform: none !important;
		margin-top: 0;
	}

	.menu__bar {
		top: 117px;
		transform: none !important;
	}

	.menus__items {
		height: 45px;
	}

	app-read-more,
	.section-header__subtitle {
		display: none;
	}

	.app-menu-item {
		&:after,
		.app-menu-item-price {
			display: none;
		}

		&:before {
			top: 50%;
			right: 10px !important;
			left: auto;
			transform: translateY(-50%);
		}

		.menu_item {
			&__price,
			&__descript,
			&__features,
			&__image_container {
				display: none;
			}
		}

		&.featured {
			border: 0;
			margin: 0 -10px;
			border-bottom: 1px solid var(--border-color);

			@media screen and (min-width: 635px) {
				margin: 0;
			}
		}
	}

	.menus + .limitTextHeight,
	.allergin,
	.disclaimer,
	.content__bottom-nav__div-buttons__call-waiter {
		display: none;
	}

	.menu-item {
		&__picture {
			display: none;
		}

		&__image_container__amount {
			top: 4px;
			right: 4px;
		}
	}

	.cart_item {
		&__image_container__image {
			display: none;
		}

		&__image_container__amount {
			right: 5px;
		}
	}

	.status__green_notice,
	.cart__green_notice {
		display: none;
	}

	.search-results {
		&.open {
			@media screen and (min-width: 768px) {
				height: calc(100vh - 56px);
			}
		}
	}
}

.waiter-mode-update {
	.menu-item-page {
		.menu-item {
			//&__feature,
			//&__features,
			//&__description,
			&__picture,
			&__back-button {
				display: none;
			}

			&__image_container__amount {
				top: 4px;
				right: 4px;
			}
		}
	}
}

.search-results-show {
	overflow: hidden;
}

.terms p {
	padding: 10px 0;
}

.heading-color {
	color: var(--heading-color);
}

.paragraph-color {
	color: var(--paragraph-color);
}

.paragraph-color-light {
	color: var(--paragraph-color);
	opacity: 60%;
}

.price-color {
	color: var(--price-color);
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
	font-size: var(--font-size-base) !important;
}
